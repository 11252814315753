import { FC } from 'react'
import StandingsRowWithConfig from '@sport1/news-styleguide/StandingsRowWithConfig'
import { BasePropsReturnType } from '@/components/Standings/Row/utils/getBaseProps'
import getTotalStats from '@/components/Standings/utils/getTotalStats'
import getDifferenceAsString from '@/components/Standings/utils/getDifferenceAsString'
import { StandingTeamPointsDeductionLabel } from '@/components/Standings/utils/standingsDataState/types'

type Props = {
    baseProps: BasePropsReturnType
    row: StandingTeamPointsDeductionLabel
}

const PercentageRow: FC<Props> = ({
    baseProps,
    row: {
        played = 0,
        won = 0,
        drawn = 0,
        lost = 0,
        homeWon = 0,
        homeDrawn = 0,
        homeLoss = 0,
        awayWon = 0,
        awayDrawn = 0,
        awayLoss = 0,
        forValue = 0,
        againstValue = 0,
        winPercentageFloat = '',
        streak = '',
    },
}) => (
    <StandingsRowWithConfig
        {...baseProps}
        columns={[
            {
                value: `${played}`,
                width: [30, 30, 48, 48],
            },
            {
                value: getTotalStats('S-N', won, drawn, lost),
                width: [40, 40, 56, 64],
            },
            {
                value: `${winPercentageFloat}`,
                bold: true,
                width: [34, 34, 56, 64],
            },
            {
                value: getTotalStats('S-N', homeWon, homeDrawn, homeLoss),
                width: [0, 0, 56, 64],
                display: ['none', 'none', 'flex', 'flex'],
            },
            {
                value: getTotalStats('S-N', awayWon, awayDrawn, awayLoss),
                width: [0, 0, 56, 64],
                display: ['none', 'none', 'flex', 'flex'],
            },
            {
                value: `${forValue}:${againstValue}`,
                width: [0, 0, 80, 80],
                display: ['none', 'none', 'flex', 'flex'],
            },
            {
                value: getDifferenceAsString(forValue, againstValue),
                width: [40, 40, 56, 64],
            },
            {
                value: streak,
                width: [34, 34, 56, 64],
            },
        ]}
    />
)

export default PercentageRow
