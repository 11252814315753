import { FC, useMemo } from 'react'
import StandingsRowWithConfig from '@sport1/news-styleguide/StandingsRowWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'
import getBaseProps from '@/components/Standings/Row/utils/getBaseProps'
import getDifferenceAsString from '@/components/Standings/utils/getDifferenceAsString'
import { useMobile } from '@/hooks/useMobile'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

const IceHockeyStandingsRow: FC<Pick<StandingsRowProps, 'competition' | 'row' | 'contextIds'>> = ({
    competition,
    row,
    contextIds,
}) => {
    const { isMobile } = useMobile()
    const baseProps = useMemo(
        () =>
            getBaseProps({
                row,
                sportIdentifier: sportIdentifiers.icehockey.name,
                contextIds,
                isMobile,
            }),
        [contextIds, isMobile, row]
    )
    if (!baseProps) {
        return null
    }

    const {
        points = 0,
        forValue = 0,
        againstValue = 0,
        played = 0,
        winNormaltime = 0,
        lossNormaltime = 0,
        winOvertime = 0,
        lossOvertime = 0,
        winShootout = 0,
        lossShootout = 0,
        pointsPerGame = 0,
    } = row

    return competition?.id &&
        ['sr:tournament:225', 'sr:tournament:267'].includes(competition.id) ? (
        <StandingsRowWithConfig
            {...baseProps}
            columns={[
                {
                    value: `${played}`,
                    width: [24, 24, 48, 48],
                },
                {
                    value: `${winNormaltime}`,
                    width: [24, 24, 48, 48],
                },
                {
                    value: `${winOvertime}`,
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${winOvertime + winShootout}`,
                    width: [30, 30, 0, 0],
                    display: ['flex', 'flex', 'none', 'none'],
                },
                {
                    value: `${winShootout}`,
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${lossShootout}`,
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${lossOvertime}`,
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${lossShootout + lossOvertime}`,
                    width: [24, 24, 0, 0],
                    display: ['flex', 'flex', 'none', 'none'],
                },
                {
                    value: `${lossNormaltime}`,
                    width: [30, 30, 48, 48],
                },
                {
                    value: `${forValue}:${againstValue}`,
                    width: [0, 0, 72, 72],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: getDifferenceAsString(forValue, againstValue),
                    width: [0, 0, 56, 56],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${points}`,
                    width: [30, 30, 48, 48],
                },
                {
                    value: `${pointsPerGame}`,
                    bold: true,
                    width: [30, 30, 48, 48],
                },
            ]}
        />
    ) : (
        <StandingsRowWithConfig
            {...baseProps}
            columns={[
                {
                    value: `${played}`,
                    width: [24, 24, 48, 48],
                },
                {
                    value: `${winNormaltime}`,
                    width: [24, 24, 48, 48],
                },
                {
                    value: `${winOvertime}`,
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${winOvertime + winShootout}`,
                    width: [30, 30, 0, 0],
                    display: ['flex', 'flex', 'none', 'none'],
                },
                {
                    value: `${winShootout}`,
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${lossShootout}`,
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${lossOvertime}`,
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${lossShootout + lossOvertime}`,
                    width: [24, 24, 0, 0],
                    display: ['flex', 'flex', 'none', 'none'],
                },
                {
                    value: `${lossNormaltime}`,
                    width: [30, 30, 48, 48],
                },
                {
                    value: `${forValue}:${againstValue}`,
                    width: [0, 0, 72, 72],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: getDifferenceAsString(forValue, againstValue),
                    width: [0, 0, 56, 56],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${points}`,
                    bold: true,
                    width: [30, 30, 48, 48],
                },
            ]}
        />
    )
}

export default IceHockeyStandingsRow
