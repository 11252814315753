import { FC, useMemo } from 'react'
import StandingsRowWithConfig from '@sport1/news-styleguide/StandingsRowWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'
import getBaseProps from '@/components/Standings/Row/utils/getBaseProps'
import getTotalStats from '@/components/Standings/utils/getTotalStats'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'
import calculateGamesBehind from '@/components/Standings/utils/calculateGamesBehind'
import { useMobile } from '@/hooks/useMobile'

const BaseballStandingsRow: FC<Pick<StandingsRowProps, 'topRankedRow' | 'row' | 'contextIds'>> = ({
    topRankedRow,
    row,
    contextIds,
}) => {
    const { isMobile } = useMobile()
    const baseProps = useMemo(
        () =>
            getBaseProps({
                row,
                sportIdentifier: sportIdentifiers.baseball.name,
                isMobile,
                contextIds,
            }),
        [contextIds, isMobile, row]
    )
    if (!baseProps) {
        return null
    }

    const {
        played = 0,
        won = 0,
        drawn = 0,
        lost = 0,
        winPercentage = '',
        homeWon = 0,
        homeDrawn = 0,
        homeLoss = 0,
        awayWon = 0,
        awayDrawn = 0,
        awayLoss = 0,
    } = row
    return (
        <StandingsRowWithConfig
            {...baseProps}
            columns={[
                {
                    value: `${played}`,
                    width: [34, 34, 56, 64],
                },
                {
                    value: getTotalStats('S-N', won, drawn, lost),
                    width: [48, 48, 56, 64],
                },
                {
                    value: `${winPercentage}`,
                    bold: true,
                    width: [48, 48, 56, 64],
                },
                {
                    value: calculateGamesBehind({ topRankedRow, trailingRow: row }),
                    width: [34, 34, 56, 56],
                },
                {
                    value: getTotalStats('S-N', homeWon, homeDrawn, homeLoss),
                    width: [0, 0, 70, 70],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: getTotalStats('S-N', awayWon, awayDrawn, awayLoss),
                    width: [0, 0, 70, 70],
                    display: ['none', 'none', 'flex', 'flex'],
                },
            ]}
        />
    )
}

export default BaseballStandingsRow
