import { FC } from 'react'
import StandingsRowWithConfig from '@sport1/news-styleguide/StandingsRowWithConfig'
import { BasePropsReturnType } from '@/components/Standings/Row/utils/getBaseProps'
import getTotalStats from '@/components/Standings/utils/getTotalStats'
import getDifferenceAsString from '@/components/Standings/utils/getDifferenceAsString'
import { StandingTeamPointsDeductionLabel } from '@/components/Standings/utils/standingsDataState/types'

type Props = {
    baseProps: BasePropsReturnType
    row: StandingTeamPointsDeductionLabel
}

const NbaRow: FC<Props> = ({
    baseProps,
    row: {
        played = 0,
        won = 0,
        drawn = 0,
        lost = 0,
        winRatio = '',
        gamesBehind = 0,
        forValue = 0,
        againstValue = 0,
        streak = '',
    },
}) => (
    <StandingsRowWithConfig
        {...baseProps}
        columns={[
            {
                value: `${played}`,
                width: [30, 30, 48, 48],
            },
            {
                value: getTotalStats('S-N', won, drawn, lost),
                width: [40, 40, 56, 64],
            },
            {
                value: `${winRatio}`,
                bold: true,
                width: [48, 48, 56, 64],
            },
            {
                value: `${gamesBehind}`,
                width: [34, 34, 40, 40],
            },
            {
                value: `${forValue}:${againstValue}`,
                width: [0, 0, 80, 80],
                display: ['none', 'none', 'flex', 'flex'],
            },
            {
                value: getDifferenceAsString(forValue, againstValue),
                width: [0, 0, 56, 64],
                display: ['none', 'none', 'flex', 'flex'],
            },
            {
                value: `${streak}`,
                width: [34, 34, 56, 64],
            },
        ]}
    />
)

export default NbaRow
