import React, { VFC } from 'react'
import { TeamStandingClientModel } from '@sport1/types/sportData'
import MatchHeader from '@sport1/news-styleguide/MatchHeader'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'

type StandingsTitleProps = {
    title: string
    team?: TeamStandingClientModel
}

const DISABLED_GROUP_TYPES = ['conference', 'division']

export const getStandingsTitle = ({ title, team }: StandingsTitleProps): string => {
    const hidePrefix =
        team?.standingGroupType && DISABLED_GROUP_TYPES.includes(team.standingGroupType)
    return hidePrefix ? title : `Gruppe ${title}`
}

const StandingsTitle: VFC<StandingsTitleProps> = ({ title, team }) => {
    return (
        <NonFlexingContainer borderBottom="1px" borderColor="tahitian-4">
            <MatchHeader>{getStandingsTitle({ title, team })}</MatchHeader>
        </NonFlexingContainer>
    )
}

export default StandingsTitle
