import React, { FC } from 'react'
import StandingsHeaderWithConfig from '@sport1/news-styleguide/StandingsHeaderWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'

const SoccerStandingsHeader: FC<Pick<StandingsRowProps, 'layoutMode'>> = ({ layoutMode }) =>
    layoutMode === 'RIGHT_COLUMN' ? (
        <StandingsHeaderWithConfig
            fontSize="12px"
            lineHeight="14px"
            columns={[
                { value: 'Sp.', width: 34 },
                { value: 'S-U-N', width: 62 },
                { value: 'Diff.', width: 34 },
                { value: 'Pkt.', width: 34 },
            ]}
        />
    ) : (
        <StandingsHeaderWithConfig
            columns={[
                {
                    value: 'Sp.',
                    width: [34, 34, 56, 64],
                },
                {
                    value: 'S-U-N',
                    width: [62, 62, 84, 92],
                },
                {
                    value: '+/-',
                    width: [0, 0, 68, 76],
                    display: ['none', 'none', 'flex'],
                },
                {
                    value: 'Diff.',
                    width: [35, 35, 56, 64],
                },
                {
                    value: 'Pkt.',
                    width: [34, 34, 56, 64],
                },
                {
                    value: 'Letzte Spiele',
                    width: [0, 0, 0, 120],
                    display: ['none', 'none', 'none', 'flex'],
                },
            ]}
        />
    )
export default SoccerStandingsHeader
