import { FC, useMemo } from 'react'
import { StandingsRowProps } from '@/components/Standings/Row'
import PercentageRow from '@/components/Standings/Row/basketball/variants/PercentageRow'
import DefaultRow from '@/components/Standings/Row/basketball/variants/DefaultRow'
import NbaRow from '@/components/Standings/Row/basketball/variants/NbaRow'
import WmEmRow from '@/components/Standings/Row/basketball/variants/WmEmRow'
import getBaseProps from '@/components/Standings/Row/utils/getBaseProps'
import { useMobile } from '@/hooks/useMobile'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

const BasketballStandingsRow: FC<
    Pick<StandingsRowProps, 'competition' | 'topRankedRow' | 'row' | 'contextIds'>
> = ({ competition, row, contextIds }) => {
    const { isMobile } = useMobile()
    const baseProps = useMemo(
        () =>
            getBaseProps({
                row,
                sportIdentifier: sportIdentifiers.basketball.name,
                contextIds,
                isMobile,
            }),
        [contextIds, isMobile, row]
    )

    if (!baseProps) {
        return null
    }

    switch (competition?.id) {
        case 'sr:tournament:132':
            return <NbaRow baseProps={baseProps} row={row} />
        case 'sr:tournament:227':
        case 'sr:tournament:156':
        case 'sr:tournament:264':
        case 'sr:tournament:138':
        case 'sr:tournament:141':
            return <PercentageRow baseProps={baseProps} row={row} />
        case 'sr:tournament:441':
        case 'sr:tournament:285':
            return <WmEmRow baseProps={baseProps} row={row} />
        default:
            return <DefaultRow baseProps={baseProps} row={row} />
    }
}

export default BasketballStandingsRow
