import { FC, useMemo } from 'react'
import StandingsRowWithConfig from '@sport1/news-styleguide/StandingsRowWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'
import getBaseProps from '@/components/Standings/Row/utils/getBaseProps'
import getTotalStats from '@/components/Standings/utils/getTotalStats'
import getDifferenceAsString from '@/components/Standings/utils/getDifferenceAsString'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'
import { useMobile } from '@/hooks/useMobile'

const FieldHockeyStandingsRow: FC<Pick<StandingsRowProps, 'row' | 'contextIds'>> = ({
    row,
    contextIds,
}) => {
    const { isMobile } = useMobile()
    const baseProps = useMemo(
        () =>
            getBaseProps({
                row,
                sportIdentifier: sportIdentifiers.field_hockey.name,
                isMobile,
                contextIds,
            }),
        [contextIds, isMobile, row]
    )
    if (!baseProps) {
        return null
    }

    const {
        points = 0,
        forValue = 0,
        againstValue = 0,
        played = 0,
        won = 0,
        drawn = 0,
        lost = 0,
    } = row

    return (
        <StandingsRowWithConfig
            {...baseProps}
            columns={[
                {
                    value: `${played}`,
                    width: [34, 34, 56, 64],
                },
                {
                    value: getTotalStats('S-U-N', won, drawn, lost),
                    width: [48, 48, 56, 64],
                },
                {
                    value: `${forValue}:${againstValue}`,
                    width: [40, 40, 56, 64],
                },
                {
                    value: getDifferenceAsString(forValue, againstValue),
                    width: [34, 34, 56, 64],
                },
                {
                    value: `${points}`,
                    bold: true,
                    width: [34, 34, 56, 64],
                },
            ]}
        />
    )
}

export default FieldHockeyStandingsRow
