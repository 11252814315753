import { FC, useMemo } from 'react'
import StandingsRowWithConfig from '@sport1/news-styleguide/StandingsRowWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'
import getBaseProps from '@/components/Standings/Row/utils/getBaseProps'
import getDifferenceAsString from '@/components/Standings/utils/getDifferenceAsString'
import getTotalStats from '@/components/Standings/utils/getTotalStats'
import { useMobile } from '@/hooks/useMobile'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

const HandballStandingsRow: FC<Pick<StandingsRowProps, 'competition' | 'row' | 'contextIds'>> = ({
    competition,
    row,
    contextIds,
}) => {
    const { isMobile } = useMobile()
    const baseProps = useMemo(
        () =>
            getBaseProps({
                row,
                sportIdentifier: sportIdentifiers.handball.name,
                isMobile,
                contextIds,
            }),
        [contextIds, isMobile, row]
    )
    if (!baseProps) {
        return null
    }

    const {
        points = 0,
        againstPoints = 0,
        forValue = 0,
        againstValue = 0,
        played = 0,
        won = 0,
        drawn = 0,
        lost = 0,
        homeWon = 0,
        homeDrawn = 0,
        homeLoss = 0,
        awayWon = 0,
        awayDrawn = 0,
        awayLoss = 0,
    } = row

    return competition?.id &&
        [
            'sr:tournament:149',
            'sr:tournament:921',
            'sr:tournament:245',
            'sr:tournament:30',
        ].includes(competition.id) ? (
        <StandingsRowWithConfig
            {...baseProps}
            columns={[
                {
                    value: `${played}`,
                    width: [34, 34, 56, 64],
                },
                {
                    value: getTotalStats('S-U-N', won, drawn, lost),
                    width: [60, 60, 76, 76],
                },
                {
                    value: getTotalStats('S-U-N', homeWon, homeDrawn, homeLoss),
                    width: [0, 0, 68, 76],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: getTotalStats('S-U-N', awayWon, awayDrawn, awayLoss),
                    width: [0, 0, 68, 76],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: `${forValue}:${againstValue}`,
                    width: [0, 0, 68, 76],
                    display: ['none', 'none', 'flex'],
                },
                {
                    value: getDifferenceAsString(forValue, againstValue),
                    width: [40, 40, 56, 64],
                },
                {
                    value:
                        competition.id === 'sr:tournament:30'
                            ? `${points}`
                            : `${points}:${againstPoints}`,
                    bold: true,
                    width: [34, 34, 72, 72],
                },
            ]}
        />
    ) : (
        <StandingsRowWithConfig
            {...baseProps}
            columns={[
                {
                    value: `${played}`,
                    width: [34, 34, 56, 64],
                },
                {
                    value: getTotalStats('S-U-N', won, drawn, lost),
                    width: [60, 60, 76, 76],
                },
                {
                    value: `${forValue}:${againstValue}`,
                    width: [0, 0, 68, 76],
                    display: ['none', 'none', 'flex'],
                },
                {
                    value: getDifferenceAsString(forValue, againstValue),
                    width: [40, 40, 56, 64],
                },
                {
                    value: `${points}`,
                    bold: true,
                    width: [34, 34, 72, 72],
                },
            ]}
        />
    )
}

export default HandballStandingsRow
