import React, { FunctionComponent, useMemo } from 'react'
import StandingsRowWithConfig from '@sport1/news-styleguide/StandingsRowWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'
import getBaseProps from '@/components/Standings/Row/utils/getBaseProps'
import getDifferenceAsString from '@/components/Standings/utils/getDifferenceAsString'
import { useMobile } from '@/hooks/useMobile'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

const SoccerStandingsRow: FunctionComponent<
    Pick<StandingsRowProps, 'row' | 'contextIds' | 'layoutMode'>
> = ({ row, contextIds, layoutMode }) => {
    const { isMobile } = useMobile()
    const baseProps = useMemo(
        () =>
            getBaseProps({
                row,
                sportIdentifier: sportIdentifiers.soccer.name,
                isMobile: isMobile || layoutMode === 'RIGHT_COLUMN',
                contextIds,
            }),
        [contextIds, isMobile, layoutMode, row]
    )
    if (!baseProps) {
        return null
    }

    const {
        points = 0,
        forValue = 0,
        againstValue = 0,
        played = 0,
        won = 0,
        drawn = 0,
        lost = 0,
        lastMatches,
    } = row

    return layoutMode === 'RIGHT_COLUMN' ? (
        <StandingsRowWithConfig
            {...baseProps}
            fontSize="14px"
            lineHeight="16px"
            imageSize={20}
            columns={[
                { value: `${played}`, width: 34 },
                { value: `${won}-${drawn}-${lost}`, width: 62 },
                { value: `${forValue}:${againstValue}`, width: 34 },
                { value: `${points}`, width: 34, bold: true },
            ]}
        />
    ) : (
        <StandingsRowWithConfig
            {...baseProps}
            columns={[
                { value: `${played}`, width: [34, 34, 56, 64] },
                { value: `${won}-${drawn}-${lost}`, width: [62, 62, 84, 92] },
                {
                    value: `${forValue}:${againstValue}`,
                    width: [0, 0, 68, 76],
                    display: ['none', 'none', 'flex'],
                },
                {
                    value: getDifferenceAsString(forValue, againstValue),
                    width: [35, 35, 56, 64],
                },
                { value: `${points}`, width: [34, 34, 56, 64], bold: true },
            ]}
            form={lastMatches ?? []}
        />
    )
}
export default SoccerStandingsRow
