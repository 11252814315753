import { FC, useMemo } from 'react'
import StandingsRowWithConfig from '@sport1/news-styleguide/StandingsRowWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'
import getBaseProps from '@/components/Standings/Row/utils/getBaseProps'
import getDartsStandingsFormat from '@/components/Standings/utils/getDartsStandingsFormat'
import getDifferenceAsString from '@/components/Standings/utils/getDifferenceAsString'
import getTotalStats from '@/components/Standings/utils/getTotalStats'
import { useMobile } from '@/hooks/useMobile'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

const DartsStandingsRow: FC<Pick<StandingsRowProps, 'competition' | 'row' | 'contextIds'>> = ({
    competition,
    row,
    contextIds,
}) => {
    const { isMobile } = useMobile()
    const baseProps = useMemo(
        () =>
            getBaseProps({
                row,
                sportIdentifier: sportIdentifiers.darts.name,
                contextIds,
                isMobile,
            }),
        [contextIds, isMobile, row]
    )
    if (!baseProps) {
        return null
    }

    const {
        points = 0,
        forValue = 0,
        againstValue = 0,
        played = 0,
        won = 0,
        drawn = 0,
        lost = 0,
    } = row

    return getDartsStandingsFormat(competition) === 'GRAND_SLAMS' ? (
        <StandingsRowWithConfig
            {...baseProps}
            columns={[
                {
                    value: `${played}`,
                    width: [28, 28, 56, 64],
                },
                {
                    value: getTotalStats('S-N', won, drawn, lost),
                    width: [34, 34, 56, 64],
                },
                {
                    value: `${forValue}:${againstValue}`,
                    width: [34, 34, 56, 64],
                },
                {
                    value: getDifferenceAsString(forValue, againstValue),
                    width: [34, 34, 56, 64],
                },
                {
                    value: `${points}`,
                    bold: true,
                    width: [28, 28, 56, 64],
                },
            ]}
        />
    ) : (
        <StandingsRowWithConfig
            {...baseProps}
            columns={[
                {
                    value: `${won}`,
                    width: [48, 48, 74, 74],
                },
                {
                    value: `${forValue}:${againstValue}`,
                    width: [40, 40, 56, 64],
                },
                {
                    value: getDifferenceAsString(forValue, againstValue),
                    width: [34, 34, 56, 64],
                },
                {
                    value: `${points}`,
                    bold: true,
                    width: [34, 34, 56, 64],
                },
            ]}
        />
    )
}

export default DartsStandingsRow
