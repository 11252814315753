import React, { VFC } from 'react'
import { TeamQualificationClientModel } from '@sport1/types/sportData'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import QualifyInfoRow from './Row'

type StandingsQualifyInfosProps = {
    qualifyInfos?: TeamQualificationClientModel[]
}

const StandingsQualifyInfos: VFC<StandingsQualifyInfosProps> = ({ qualifyInfos }) => {
    if (!qualifyInfos?.length) return null

    const filteredQualifies = qualifyInfos?.filter(
        (value, index, self) => self.map(t => t.color).indexOf(value.color) === index
    )

    return (
        <NonFlexingContainer spaceX="standard" spaceTop="standard">
            {filteredQualifies.map(info => (
                <QualifyInfoRow key={info.label} {...info} />
            ))}
        </NonFlexingContainer>
    )
}

export default StandingsQualifyInfos
