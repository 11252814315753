import React, { VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { CompetitionClientModel } from '@sport1/types/sportData'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import StandingsTitle from '../Title'
import StandingsHeader from '../Header'
import StandingsRow from '../Row'
import { StandingWithPointsDeductionLabel } from '../utils/standingsDataState/types'

interface StandingsListProps extends SystemSpacingsProps {
    competition?: CompetitionClientModel
    standing: StandingWithPointsDeductionLabel
    contextIds?: string[]
    layoutMode?: 'RIGHT_COLUMN' | 'RESPONSIVE'
    testID?: string
}

const StandingsList: VFC<StandingsListProps> = ({
    standing,
    paddingBottom,
    paddingTop,
    competition,
    contextIds,
    layoutMode,
    testID,
}) => {
    const team = standing.teams && standing.teams[0]
    return (
        <NonFlexingContainer
            testID={testID}
            paddingBottom={paddingBottom}
            paddingTop={paddingTop}
            backgroundColor="pearl"
        >
            {standing.title ? <StandingsTitle title={standing.title} team={team} /> : null}
            <StandingsHeader
                sportIdentifier={competition?.sportGroup?.sportIdentifier}
                competition={competition}
                team={team}
                layoutMode={layoutMode}
            />
            {standing.teams?.map((item, index, teams) => (
                <StandingsRow
                    key={`standingsRow-${competition?.id}-${standing.title}-${index}`}
                    sportIdentifier={competition?.sportGroup?.sportIdentifier}
                    contextIds={contextIds}
                    competition={competition}
                    row={item}
                    layoutMode={layoutMode}
                    topRankedRow={teams[0]}
                />
            ))}
        </NonFlexingContainer>
    )
}

export default StandingsList
