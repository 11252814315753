import { StandingsRowWithConfigProps } from '@sport1/news-styleguide/StandingsRowWithConfig'
import { StandingTeamPointsDeductionLabel } from '@/components/Standings/utils/standingsDataState/types'
import getDeductionLabel from '@/components/Standings/utils/getDeductionLabel'
import getTendency from '@/components/Standings/utils/getTendency'
import { createTeamHref } from '@/utils/navigation/Navigator'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

export type BasePropsReturnType = Pick<
    StandingsRowWithConfigProps,
    | 'testID'
    | 'rank'
    | 'competitorName'
    | 'competitorLogoUrl'
    | 'leagueQualifierColor'
    | 'additionalInfoLabel'
    | 'tendency'
    | 'isCompetitorBold'
    | 'href'
    | 'hasDivider'
>

const getBaseProps = ({
    row,
    sportIdentifier,
    isMobile,
    contextIds = [],
}: {
    row: StandingTeamPointsDeductionLabel
    sportIdentifier: string
    isMobile?: boolean
    contextIds?: string[]
}): BasePropsReturnType | undefined => {
    const { team, position = 0, startDayPosition, teamQualification, pointsDeductions } = row
    if (!team) return undefined

    const { shortName, name, imageUrl, id, nationality } = team
    const teamName = isMobile && shortName ? shortName : name
    if (!teamName) return undefined

    const result: BasePropsReturnType = {
        testID: `${sportIdentifier}-standing-row-${teamName}`,
        rank: position,
        competitorName: teamName,
        competitorLogoUrl: imageUrl || '',
        leagueQualifierColor: teamQualification?.color,
        additionalInfoLabel: pointsDeductions ? getDeductionLabel(pointsDeductions) : undefined,
        tendency: getTendency(position, startDayPosition),
        isCompetitorBold: !!id ? contextIds?.includes(id) : false,
        href: sportIdentifier === sportIdentifiers.soccer.name ? createTeamHref(team) : undefined,
        hasDivider: true,
    }

    if (sportIdentifier === sportIdentifiers.darts.name) {
        result.competitorLogoUrl = nationality?.imageUrl || ''
    }

    return result
}
export default getBaseProps
