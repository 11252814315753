import { FC } from 'react'
import StandingsHeaderWithConfig from '@sport1/news-styleguide/StandingsHeaderWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'

const VolleyballStandingsHeader: FC<Pick<StandingsRowProps, 'competition'>> = ({ competition }) =>
    competition?.id && ['sr:tournament:500', 'sr:tournament:501'].includes(competition.id) ? (
        <StandingsHeaderWithConfig
            columns={[
                {
                    value: 'Sp.',
                    width: [34, 34, 56, 64],
                },
                {
                    value: 'S-N',
                    width: [40, 40, 56, 64],
                },
                {
                    value: 'Heim',
                    width: [0, 0, 64, 64],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'Gast',
                    width: [0, 0, 64, 64],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'Sätze',
                    width: [40, 40, 56, 64],
                },
                {
                    value: 'Diff.',
                    width: [0, 0, 56, 64],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'Pkt.',
                    width: [34, 34, 56, 64],
                },
            ]}
        />
    ) : (
        <StandingsHeaderWithConfig
            columns={[
                {
                    value: 'Sp.',
                    width: [34, 34, 56, 64],
                },
                {
                    value: 'S-N',
                    width: [40, 40, 56, 64],
                },
                {
                    value: 'Sätze',
                    width: [40, 40, 56, 64],
                },
                {
                    value: 'Diff.',
                    width: [0, 0, 56, 64],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'Pkt.',
                    width: [34, 34, 56, 64],
                },
            ]}
        />
    )

export default VolleyballStandingsHeader
