import React, { VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import StandingsLegendRow from '@sport1/news-styleguide/StandingsLegendRow'
import { TeamQualificationClientModel } from '@sport1/types/sportData'

type QualifyInfoRowProps = Pick<TeamQualificationClientModel, 'color' | 'label'>

const QualifyInfoRow: VFC<QualifyInfoRowProps> = ({ color, label }) => {
    if (!color || !label) return null
    return (
        <NonFlexingContainer height={18} marginTop="spacing-4">
            <StandingsLegendRow key={label} color={color} label={label} />
        </NonFlexingContainer>
    )
}

export default QualifyInfoRow
