import React, { FunctionComponent } from 'react'
import { CompetitionClientModel, PointsDeductionClientModel } from '@sport1/types/sportData'
import { StandingTeamPointsDeductionLabel } from '../utils/standingsDataState/types'
import SoccerStandingsRow from './soccer'
import DartsStandingsRow from './darts'
import HandballStandingsRow from './handball'
import FieldHockeyStandingsRow from './fieldhockey'
import VolleyballStandingsRow from '@/components/Standings/Row/volleyball'
import BaseballStandingsRow from '@/components/Standings/Row/baseball'
import BasketballStandingsRow from '@/components/Standings/Row/basketball'
import AmericanFootballStandingsRow from '@/components/Standings/Row/americanFootball'
import IceHockeyStandingsRow from '@/components/Standings/Row/icehockey'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

export type PointsDeductionClientModelWithLabel = PointsDeductionClientModel & {
    label?: number | string
}

export type StandingsRowProps = {
    contextIds?: string[]
    sportIdentifier?: string
    row: StandingTeamPointsDeductionLabel
    layoutMode?: 'RIGHT_COLUMN' | 'RESPONSIVE'
    competition?: CompetitionClientModel
    topRankedRow: StandingTeamPointsDeductionLabel
}

const StandingsRow: FunctionComponent<StandingsRowProps> = ({
    sportIdentifier,
    layoutMode = 'RESPONSIVE',
    ...props
}) => {
    switch (sportIdentifier) {
        case sportIdentifiers.soccer.name:
            return <SoccerStandingsRow layoutMode={layoutMode} {...props} />
        case sportIdentifiers.darts.name:
            return <DartsStandingsRow {...props} />
        case sportIdentifiers.handball.name:
            return <HandballStandingsRow {...props} />
        case sportIdentifiers.field_hockey.name:
            return <FieldHockeyStandingsRow {...props} />
        case sportIdentifiers.indoor_volleyball.name:
            return <VolleyballStandingsRow {...props} />
        case sportIdentifiers.basketball.name:
            return <BasketballStandingsRow {...props} />
        case sportIdentifiers.baseball.name:
            return <BaseballStandingsRow {...props} />
        case sportIdentifiers.american_football.name:
            return <AmericanFootballStandingsRow {...props} />
        case sportIdentifiers.icehockey.name:
            return <IceHockeyStandingsRow {...props} />
    }
    return null
}
export default StandingsRow
